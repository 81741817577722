import React from 'react';
import {FaRegFrownOpen} from 'react-icons/fa';
import styled from 'styled-components';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';

const StyledIcon = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const StyledText = styled('h2')`
  color: white;
  text-align: center;
  padding-top: 30px;
`;

export default function Error() {
  return (
    <Layout>
      <SEO title="Error!" />
      <StyledIcon>
        <FaRegFrownOpen size={140} />
      </StyledIcon>
      <StyledText>Nothing to see here, sorry. Maybe go back?</StyledText>
    </Layout>
  );
}
